import React from 'react'
import PropTypes from 'prop-types'
import { SplideSlide, SplideTrack } from '@splidejs/react-splide'
import CourseCard from '../card'
import { SliderContainer } from './style'
import { Link } from 'gatsby'
import { Chevron } from '../../../../images/chevron'
import { sliderStopAndRead } from '../../../google-tag-manager/events'
import WebinarCard from '../../../webinars/card'

const Slider = ({ title, subtitle, list }) => {
  const isWebinar = list[0].templateWebinar !== undefined
  return (
    <div
      id={'courses'}
      className={`w-full ${
        isWebinar ? 'bg-white' : 'bg-gray-100'
      } py-6 lg:py-16`}
    >
      <div
        className={
          'flex flex-col lg:text-center w-full px-4 md:px-6 max-w-[75ch] mx-auto'
        }
      >
        <h3
          className={
            'font-medium text-blue-dark mb-3.5 text-3xl md:text-4xl lg:text-5xl'
          }
        >
          {title ?? ''}
        </h3>
        <p className={'text-blue-dark text-lg'}>{subtitle ?? ''}</p>
      </div>
      <SliderContainer
        tag={'section'}
        hasTrack={false}
        aria-label={title}
        arrows={'false'}
        options={{
          width: '100%',
          gap: '2rem',
          autoWidth: true,
          trimSpace: false,
          easing: 'ease-out',
          focus: 'center',
          start: '0',
          onDragEnd: sliderStopAndRead('kurs'),
        }}
      >
        <div>
          <SplideTrack className={'py-10'}>
            {list.map((item, index) => (
              <SplideSlide key={index}>
                <div className={'w-[300px] lg:w-[365px]'}>
                  {!isWebinar ? (
                    <CourseCard
                      title={item.title}
                      date={item.templateCourse.date}
                      isDigital={item.templateCourse.digital}
                      location={item.templateCourse.place}
                      link={item.templateCourse.link}
                      hasWhiteBackground={true}
                    />
                  ) : (
                    <WebinarCard
                      key={index}
                      title={item.title}
                      date={item.date}
                      link={item.uri}
                      categories={item.categories.nodes}
                      hasWhiteBackground={true}
                      paid={item.templateWebinar.paid}
                      price={item.templateWebinar.price}
                    />
                  )}
                </div>
              </SplideSlide>
            ))}
          </SplideTrack>
          <div
            className={
              'splide__arrows flex gap-x-4 md:gap-x-11 justify-center items-start md:items-center w-full'
            }
          >
            <button
              className={`splide__arrow splide__arrow--prev bg-blue-lighter rounded-full h-8 w-8 hidden sm:block disabled:opacity-50
                    ${list.length < 3 && 'sm:hidden'}
                  `}
            >
              <Chevron orientation={'left'} size={'32'} />
            </button>

            <Link
              to={'/kurs'}
              className={
                'bg-blue-main font-medium text-blue-lightest text-base pt-3.5 pb-4 px-[18px] rounded-full  hover:bg-blue-hover active:bg-blue-dark '
              }
            >
              Se alle {title.toLowerCase()}
            </Link>

            <button
              className={`splide__arrow splide__arrow--next bg-blue-lighter rounded-full h-8 w-8 hidden sm:block disabled:opacity-50
                    ${list.length < 3 && 'sm:hidden'}
                  `}
            >
              <Chevron orientation={'right'} size={'32'} />
            </button>
          </div>
        </div>
      </SliderContainer>
    </div>
  )
}

export default Slider

Slider.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}