import tw, { styled } from 'twin.macro'
import { Splide } from '@splidejs/react-splide'

export const Root = styled.div`
  ${tw`
  w-full
  bg-gray-100
  py-6 lg:py-16
`}
`

export const SliderContainer = styled(Splide)`
  ${tw`
  w-full
  ml-auto
`}
`
